<template>
<v-card class="ma-0">
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{labels.title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')" v-if="$isAuthorized( 'TrackingSheetConvention', 'update' )" small class="mr-2" color="orange" @click.native="sheetMode = 'transfer'" :disabled="saveProgress" title="transférer la convention">
            <v-icon left>mdi-transfer</v-icon>
        </v-btn>
        <!-- <v-btn v-if="objConvention.tcv_id > 0 && sheetMode !== 'document'" class="mr-2" small color="secondary" @click.native="sheetMode = 'document'" :disabled="saveProgress" title="Voir les documents">
            <v-icon left>mdi-briefcase</v-icon> Documents
        </v-btn> -->
        <v-btn v-if="sheetMode !== 'convention'" small class="mr-2" color="primary" @click.native="sheetMode = 'convention'" :disabled="saveProgress" title="Convention">
            <v-icon left>mdi-script-text-outline</v-icon> Convention
        </v-btn>
        <v-btn icon color="" @click.native="switchModal('-', 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-tabs v-model="activeTab" grow light show-arrows="mobile">
        <v-tabs-slider color="green"></v-tabs-slider>
        <!-- <v-tab><v-icon class="mr-1">mdi-file-document-box-check</v-icon><span class="hidden-sm-and-down">Procédures</span></v-tab> -->
        <v-tab class="pl-1 pr-1"><v-icon class="hidden-md-and-up mr-1">mdi-file</v-icon><span class="hidden-sm-and-down">Fiche</span></v-tab>
        <v-tab class="pl-1 pr-1" :disabled="!objConvention.tcv_id">
            <v-icon class="hidden-md-and-up mr-1" :disabled="true">mdi-file</v-icon>
            <span class="hidden-sm-and-down">Fichiers
            <!-- <v-badge color="green" :content="(doc_ids) ? doc_ids : '0'">Fichiers</v-badge> -->
            </span>
        </v-tab>
        <v-tab class="pl-1 pr-1"><v-icon class="hidden-md-and-up mr-1">mdi-file</v-icon><span class="hidden-sm-and-down">Évènements</span></v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-card color="grey lighten-4 pt-2" flat v-if="activeTab === 0">
        <v-layout wrap style="background-color:white;" class="pa-0 elevation-1" v-if="sheetMode == 'convention' && $isAuthorized( 'TrackingSheetConvention', 'update' )">
            <v-flex xs6 style="border-right:1px solid #E0E0E0;">
                <v-list dense class="pa-0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><span><v-icon>mdi-account</v-icon>{{ objConvention.contact.ctt_middlename }}</span></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text class="text-truncate" style="max-width:230px;"><v-icon>mdi-office-building</v-icon>{{ objConvention.contact.ctt_company_name }}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="(objConvention.contact.ctt_mobile && !objConvention.contact.ctt_mobile.length)">
                                <v-icon>mdi-cellphone</v-icon>{{ formatPhoneNumber(objConvention.contact.ctt_mobile) }}
                            </v-list-item-title>
                            <v-list-item-title v-else :title="formatPhoneNumber(objConvention.contact.ctt_mobile)">
                                <v-icon>mdi-phone</v-icon>{{ formatPhoneNumber(objConvention.contact.ctt_phone) }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text class="text-truncate" style="max-width:230px;"><v-icon>mdi-email</v-icon>{{ objConvention.contact.ctt_email }}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-flex>
            <v-flex xs6 >
                <v-list dense class="pa-0" v-if="objConvention.company.ctt_id">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><v-icon>mdi-account</v-icon><span>{{ objConvention.company.ctt_middlename }}</span></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text class="text-truncate" style="max-width:230px;"><v-icon>mdi-office-building</v-icon>{{ objConvention.company.ctt_company_name }}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><v-icon>mdi-phone</v-icon>{{ formatPhoneNumber(objConvention.company.ctt_phone) }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text class="text-truncate" style="max-width:230px;"><v-icon>mdi-email</v-icon>{{ objConvention.company.ctt_email }}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-flex>
            <v-flex xs2 ></v-flex>
        </v-layout>
        <v-card-text v-if="sheetMode == 'convention'" class="mt-2">
            <v-form ref="userForm" v-model="validForm" lazy-validation>
                <v-layout row wrap>
                <v-flex xs6 class="pa-2">
                    <autoCompMulti v-if="!loading.getItem" :title="'Contact'" ressource="contacts" :listObject.sync="autoComplete.tcv_ctt_id"
                        :listParent.sync="autoCompleteSource.ctt" 
                        :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false }"
                        :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )" /> 
                    <!--<auto-contact v-if="!loading.getItem" :title="'Contact'" ressource="contacts" :listObject.sync="autoComplete.tcv_ctt_id"
                        :listParent.sync="autoCompleteSource.ctt" :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false }">
                    </auto-contact>  -->
                </v-flex>
                <v-flex xs6 class="pa-2" v-show="!(this.objTrackingSheet.trs_typ_id == 'SIT02')">
                    <autoCompMulti v-if="!loading.getItem" :title="'Société / Entreprise'" ressource="contacts" :listObject.sync="autoComplete.tcv_ctt_id_company"
                        :listParent.sync="autoCompleteSource.ctc" 
                        :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false, preprendIcon:'mdi-domain' }"
                        :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )" />

                </v-flex>
                <v-flex xs3 class="pa-2">
                    <v-select 
                        item-text="sta_label" item-value="sta_id"
                        :items="cacheState" v-model="objConvention.tcv_sta_id" label="Statut" single-line :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )">
                    </v-select>
                </v-flex>
                <v-flex xs3 class="pa-2">
                    <v-text-field label="Code" v-model="objConvention.tcv_code" required :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )"></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-2">
                    <DatePicker v-if="!loading.getItem" :label="'Date signature'" :objectForm="objConvention" fieldName='tcv_date_sign' :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )"></DatePicker>
                </v-flex>
                <v-flex xs4 class="pa-2">
                    <v-select 
                        :items="typeConv" v-model="objConvention.tcv_typ_id" :label="labels.type"
                        item-text="typ_name" item-value="typ_id" :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )">
                    </v-select>
                </v-flex>
                <v-flex xs4 class="pa-2" v-show="!(this.objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Montant" v-model="objConvention.tcv_amount_ht" required :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )"></v-text-field>
                </v-flex>
                <v-flex xs4 class="pa-2" v-show="!(this.objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-select 
                        :items="typeUpdateConv" v-model="objConvention.tcv_typ_id_update" label="Type rémunération"
                        item-text="typ_name" item-value="typ_id" :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )">
                    </v-select>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(this.objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Surface" v-model="objConvention.tcv_area" required :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )"></v-text-field>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(this.objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Linéaire" v-model="objConvention.tcv_area_linear" required :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )"></v-text-field>
                </v-flex>
                <v-flex xs3 class="pa-2">
                    <DatePicker :label="'Date début'" :objectForm="objConvention" fieldName='tcv_date' :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )"></DatePicker>
                </v-flex>
                <v-flex xs3 class="pa-2">
                    <DatePicker :label="'Date fin'" :objectForm="objConvention" fieldName='tcv_deadline' :disabled="!$isAuthorized( 'TrackingSheetConvention', 'update' )"></DatePicker>
                </v-flex>
                <v-flex xs12 class="pa-2">
                    <v-textarea label="Commentaire" rows="1" v-model="objConvention.tcv_custom_fields['tcv_comment']"></v-textarea>
                </v-flex>
                </v-layout>
            </v-form>
        </v-card-text>
        <!--  Select a convention if not -->
        <v-card-text v-if="sheetMode == 'transfer'">
        <v-layout row wrap>
            <v-flex xs12 class="pa-2">
                <autoCompMulti v-if="!loading.getItem" :title="'Fiche'" ressource="trackingsheets" :listObject.sync="autoComplete.tcv_trs_id"
                    :listParent.sync="autoCompleteSource.trs" 
                    :fields="trackingsheetFields" />
            </v-flex>
        </v-layout>
        </v-card-text>
        <!--  show document 
        <v-card-text v-if="sheetMode == 'document'" class="ma-0 pa-0">
            <documentTab v-if="sheetMode == 'document'" :doc_xxx_id="objConvention.tcv_id" :table_id="'tcv'" :routeState="routeState" />
        </v-card-text>
        -->
        <!-- <event-tab :table_id="'adu'" :usr_type="'control'"></event-tab> -->
        <v-card-actions style="background-color:white;" v-if="sheetMode == 'convention'">
            <v-btn v-if="$isAuthorized( 'TrackingSheetConvention', 'destroy' )" small class="ma-0" color="error" @click.native="deleteItem()" :disabled="saveProgress" :title="'Supprimer ' + labels.name">
                <v-icon left>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn v-if="$isAuthorized( 'TrackingSheetConvention', 'update' )" small class="ma-0 ml-2" color="brown" @click.native="archiveItem()" :disabled="saveProgress" :title="'Archiver' + labels.name">
                <v-icon left>mdi-archive-arrow-down-outline</v-icon>
            </v-btn>
            <v-menu transition="slide-y-transition" offset-y bottom style="z-index:2500" v-if="isAuthorized( 'TrackingSheet', 'update' )">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-2" small outlined light :color="'green'" v-bind="attrs" v-on="on">
                        <v-icon left>mdi-plus</v-icon>Action
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item :loading="loading.reportSheet" dense><v-list-item-content>
                        <v-btn @click.native="storeAndLogForm('COV02')" v-if="isAuthorized( 'TrackingSheet', 'update' )" block small outlined light :color="'primary'">Avenant</v-btn>
                    </v-list-item-content></v-list-item>
                    <v-list-item dense v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')"><v-list-item-content>
                        <v-btn @click.native="storeAndLogForm('COV03')" v-if="isAuthorized( 'TrackingSheet', 'update' )" block small outlined light :color="'orange'">Changement de propriétaire</v-btn>
                    </v-list-item-content></v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn color="success" v-if="!loading.getItem && $isAuthorized( 'TrackingSheetConvention', 'update' )" :loading="saveProgress" @click.native="saveForm()" :disabled="!validForm">Enregistrer</v-btn>
            <v-btn color="primmary" @click.native="switchModal('-', 0)">Retour</v-btn>
        </v-card-actions>
        <v-card-actions v-if="(sheetMode == 'transfer' && autoComplete.tcv_trs_id > 0)">
            <v-spacer></v-spacer>
            <v-btn rounded  color="success" 
                @click.native="transferItem()">
                OK
            </v-btn>
        </v-card-actions>
    </v-card>
    <!-- <tab-doc v-if="activeTab === 1 && showTab == true" :doc_xxx_id="eveId" :table_id="'eve'" :totalDoc.sync="doc_ids"
        :routeState="{ siteView:false, siteEdit:true, view:false, edit:true, add:true, state: 'modifier' }" style="min-height: 474px;">
    </tab-doc> -->
    <documentTab v-if="activeTab === 1 && showTab == true" :doc_xxx_id="objConvention.tcv_id" :table_id="'tcv'" :routeState="routeState" />
    <eventTab v-if="activeTab === 2 && showTab == true" :eve_xxx_id="objConvention.tcv_id" :table_id="'tcv'" :routeState="routeState" :title="'Évènements'" />
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import eventTab from '../components/Tab_event.vue'
import autoCompMulti    from './Autocomplete_multi.vue'
//import autoCompContact  from './Autocomplete_contact.vue'
import { cacheGetters } from "../store/cache.module"
import documentTab  from '../components/Tab_document.vue'
import { usrGetters }   from "../store/user.module"
import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'edit_convention',
    components: {
        autoCompMulti,
        documentTab,
        eventTab,
        DatePicker
    },
    props: ['switchModal', 'objTrackingSheet', 'tcv_id', 'routeState'],
    data: function () {
        return {
            dateMenu : {},
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            sheetMode: 'convention',
            validForm   : true,
            typeConv: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? cacheGetters.getTypes('ACQ') : cacheGetters.getTypes('COV'),
            typeUpdateConv: cacheGetters.getTypes('FRQ'),
            cacheState: cacheGetters.getStates('GEN') || [],
            labels: {
                title: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Détail plan de gestion' : 'Détails convention',
                type: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Type plan' : 'Type de convention',
                name: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'plan de gestion' : 'convention',
                names: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'plans de gestion' : 'cdc',
                typId : (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'ACQ' : 'COV',
            },
            objConvention: {
                tcv_id: '', tcv_trs_id: this.objTrackingSheet.trs_id, tcv_ctt_id: null, tcv_ctt_id_company: '', 
                tcv_sta_id: 'GEN01', tcv_typ_id: 'COV01', tcv_code: '',
                tcv_date: null, tcv_deadline: '', tcv_date_sign: null, tcv_typ_id_update: '', tcv_update_date: null, 
                tcv_update_value: '', tcv_amount_ht: '', tcv_tax: '',
                tcv_custom_fields: {}, tcv_end_contract: '', tcv_payment_direct: '', tcv_payment_frequency: '',
                tcv_area: 0, tcv_area_linear: 0,
                contact: {},
                company: {}
            },
            form:{},
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            locDateFormat: dateLocale, // Format de date
            autoComplete: {
                tcv_trs_id: null,
                tcv_ctt_id: null,
                tcv_ctt_id_company: null,
            },
            autoCompleteSource:{
                ctt: [],
                ctc: [],
                trs: [],
            },
            trackingsheetFields: { 
                index: 'trs_id', text: 'trs_coddt,trs_name', search:'trs_coddt', 
                multiSelect:false, autoselectfirst: true,
            },
            activeTab: 0, showTab: true,
            isAuthorized: usrGetters.getScopeRight
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.form.usr_password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        }
    },
    mounted:function (){
        if( this.tcv_id > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/trackingconvention/' + this.tcv_id + '/?include=contact,company' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objConvention[prop] = response.data.data[prop]['data']
                        } else if( response.data.data[prop] ) {
                            this.objConvention[prop] = response.data.data[prop]
                        }
                    }
                    this.objConvention.tcv_custom_fields = (!this.objConvention.tcv_custom_fields) ? {} : this.objConvention.tcv_custom_fields
                    if( this.objConvention.contact ){
                        this.autoCompleteSource.ctt.push(this.objConvention.contact)
                        this.autoComplete.tcv_ctt_id = this.objConvention.tcv_ctt_id
                    }
                    if( this.objConvention.company ){
                        this.autoCompleteSource.ctc.push(this.objConvention.company)
                        this.autoComplete.tcv_ctt_id_company = this.objConvention.tcv_ctt_id_company
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        async saveForm() {
            this.objConvention.tcv_ctt_id         = this.autoComplete.tcv_ctt_id || null
            this.objConvention.tcv_ctt_id_company = this.autoComplete.tcv_ctt_id_company  || null
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.objConvention.tcv_id > 0 ){
                this.$http.put( '/trackingconvention/' + this.objConvention.tcv_id, this.objConvention ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.updateSpecificationDate()
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('-', 0, true, true)
                })
            } else {
                this.$http.post( '/trackingconvention', this.objConvention ).then( () => {
                    //this.objConvention.tcv_id = response.data.data.tcv_id
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('convention', 0, false, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('convention', 0, false, true)
                })
            }
        },
        updateSpecificationDate(){
            return new Promise( (resolve) => {
                this.$root.$confirm('Mettre à jour les dates des ' + this.labels.names + ' ?', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                    if( confirm ){
                        this.$http.put( 'trackingconvention/' + this.objConvention.tcv_id + '/specification', this.objConvention ).then( () => {
                            this.switchModal('convention', 0, false, true)
                            resolve('resolved')
                        })
                    } else {
                        this.switchModal('convention', 0, false, true)
                        resolve('resolved')
                    }
                })
            })
        },
        storeAndLogForm(type) {
            this.objConvention.tcv_ctt_id         = this.autoComplete.tcv_ctt_id || null
            this.objConvention.tcv_ctt_id_company = this.autoComplete.tcv_ctt_id_company  || null
            this.objConvention.tcv_typ_id_new     = type
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.objConvention.tcv_id > 0 ){
                this.$http.post( '/trackingconvention/' + this.objConvention.tcv_id + '/storeandlog', this.objConvention ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('convention', 0, false, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('-', 0, true, true)
                })
            }            
        },
        deleteItem(){
            this.$root.$confirm('Supprimer ' + this.labels.name, 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/trackingconvention/' + this.objConvention.tcv_id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                        this.switchModal('convention', 0, false, true)
                    })
                }
            })
        },
        archiveItem(){
            this.$root.$confirm('Archiver ' + this.labels.name, 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.objConvention.tcv_sta_id = 'GEN04'
                    this.$http.put( '/trackingconvention/' + this.objConvention.tcv_id, this.objConvention ).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                        this.switchModal('convention', 0, false, true)
                    })
                }
            })
        },
        transferItem(){
            if( this.objConvention.tcv_id > 0 ){
                this.objConvention.tcv_trs_id = this.autoComplete.tcv_trs_id
                this.$http.put( '/trackingconvention/switch/' + this.objConvention.tcv_id, this.objConvention ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('convention', 0, false, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('convention', 0, false, true)
                })
            } else {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' })
                    this.switchModal('convention', 0, false, true)
            }
        },
        formatPhoneNumber(number){
            let nonInt      = /\D/g
            let allNumbers  = /.*(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/
            let formatStyle = "0$1 $2 $3 $4 $5"
            if( number && number.length > 3 ){
                return number.replace(nonInt, '').replace(allNumbers, formatStyle)
            } else {
                return ''
            }
        }
    }
}
</script>